<template>
    <div class="screenHeight">
        <div id="map"></div>
        <div class="currentLocation">
            <button class="custom-map-control-button" @click="initButton()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                     viewBox="0 0 23 23">
                    <defs>
                        <clipPath id="clip-Locate_team">
                            <rect width="26" height="26"/>
                        </clipPath>
                    </defs>
                    <g id="Locate_team" data-name="Locate team" clip-path="url(#clip-Locate_team)">
                        <g id="Group_898" data-name="Group 898" transform="translate(-653.958 9.752)">
                            <path id="Path_1291" data-name="Path 1291"
                                  d="M673.433.748a8.156,8.156,0,0,0-.1-.892,8,8,0,0,0-6.871-6.539V-8.752h-2v2.069A7.992,7.992,0,0,0,657.483.748h-2.525v2H657.6a8,8,0,0,0,6.855,6.431v3.069h2V9.179a8,8,0,0,0,6.854-6.431h2.646v-2Zm-7.975,6.5a6,6,0,1,1,6-6A6.006,6.006,0,0,1,665.458,7.248Z"/>
                            <g id="Ellipse_4" data-name="Ellipse 4">
                                <circle id="Ellipse_209" data-name="Ellipse 209" cx="3" cy="3" r="3"
                                        transform="translate(662.458 -1.752)"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <little-info-window v-if="selectedRoad!== null && roadIsSelected === true"></little-info-window>
            <router-link to="/checkpoints" class="seeList">
                SEE LIST
            </router-link>
        </div>
        <mobile-navigation></mobile-navigation>
    </div>
</template>

<script>

import MobileNavigation from '@/components/MobileNavigation';
import LittleInfoWindow from '@/components/LittleInfoWindow.vue';
import mapsLoader from '../services/gMapsLoader';
import Road from '@/models/Road';

let map = null;
let userMarker = null;
const markers = [];

export default {
    data() {
        return {
            infoWindow: "",
            roads: [],
            defaultCenter: {
                lat: 47.52,
                lng: 16.11
            },
            addClickEvent: false,
            markerClicked: false,
            pathLine: null,
            selectedRoad: null,
            roadIsSelected: false,
            roadId: null,
        }
    },

    async mounted() {
        const google = await mapsLoader();
        this.roads = this.$store.getters.getCheckpointList;

        if (this.$store.getters.getLocation) {
            this.defaultCenter = this.$store.getters.getLocation;
        }

        // eslint-disable-next-line no-undef
        map = new google.maps.Map(document.getElementById('map'), {
            center: this.defaultCenter,
            zoom: 8,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControlOptions: {
                // eslint-disable-next-line no-undef
                position: google.maps.ControlPosition.RIGHT_CENTER,
            },
        });

        map.addListener("click", () => {
            if (this.addClickEvent === true) {
                this.$store.dispatch('setSelectedRoad', null);
                this.$store.dispatch('setIsRoadSelected', false);
                this.addClickEvent = false;
            }
        });

        if (this.$store.getters.getLocation) {
            this.setUserMarker(this.$store.getters.getLocation);
        }

        if (this.roads) {
            this.setLocationMarker();
        }
    },
    methods: {
        initButton() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.$store.dispatch('setLocation', pos);
                        this.setUserMarker(pos);
                        map.setCenter(pos);
                    },
                    () => {
                        this.handleLocationError(true, this.infoWindow, map.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                this.handleLocationError(false, this.infoWindow, map.getCenter());
            }
        },
        handleLocationError(browserHasGeolocation, infoWindow, pos) {
            infoWindow.setPosition(pos);
            infoWindow.setContent(
                browserHasGeolocation ?
                    "Error: The Geolocation service failed." :
                    "Error: Your browser doesn't support geolocation."
            );
            infoWindow.open(map);
        },

        setUserMarker(location) {
            if (userMarker === null) {
                // eslint-disable-next-line no-undef
                userMarker = new google.maps.Marker({
                    position: location,
                    title: 'my location',
                    icon: '/img/userMarker.png',
                    map,
                });
            }

            userMarker.setPosition({
                lat: location.lat,
                lng: location.lng,
            });
        },

        async setLocationMarker() {
            let roads = [];
            for (let i = 0; i < this.roads.length; i++) {
                roads[i] = await Road.find(this.roads[i]);
            }
            for (let i = 0; i < roads.length; i++) {
                let pos = {
                    lat: roads[i].lat,
                    lng: roads[i].long,
                };
                // eslint-disable-next-line no-undef
                const marker = new google.maps.Marker({
                    position: pos,
                    title: (roads[i].id + 1).toString(),
                    icon: '/img/locationMarker.png',
                    map,
                });
                markers.push(marker);
                marker.addListener("click", () => {
                    this.$store.dispatch('setSelectedRoad', roads[i].id);
                    this.$store.dispatch('setIsRoadSelected', true);
                    this.markerClicked = true;
                    map.panTo(marker.getPosition());
                });
            }

        },

        checkSelection() {
            if (this.$store.getters.getIsRoadSelected === true) {
                this.addClickEvent = true;
                this.roadIsSelected = true;
            }
        },
    },
    watch: {
        "$store.getters.getIsRoadSelected": {
            handler: function () {
                if (this.$store.getters.getIsRoadSelected === true) {
                    this.checkSelection();
                } else {
                    this.roadIsSelected = false;
                }
            },
            immediate: true
        },
        "$store.getters.getSelectedRoad": {
            handler: function () {
                if (this.$store.getters.getSelectedRoad) {
                    this.selectedRoad = this.$store.getters.getSelectedRoad;
                } else {
                    this.selectedRoad = null;
                }
            },
            immediate: true
        },
    },
    components: {
        MobileNavigation,
        LittleInfoWindow
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.currentLocation {
    .custom-map-control-button {
        position: absolute;
        bottom: 145px;
        right: 15px;
        padding: 15px;
        z-index: 10;
        border: none;
        border-radius: 50%;
        background-color: white;
        color: $secondary;

        &:focus {
            border: none;
            outline: none;
        }

        svg {
            fill: $secondary;
            font-size: 20px;
            display: block;
            margin: auto;
        }
    }

    .seeList {
        position: fixed;
        right: 12px;
        bottom: 85px;
        background-color: white;
        padding: 8px 12px;
        text-decoration: none;
        color: #707070;
        box-shadow: 0px 3px 6px #e8e8e8;
    }
}

#map {
    width: 100%;
    height: 90vh;

    @media (min-width: 768px) {
        height: 100vh;
    }
}
</style>
